<template>
  <ProfileWrapper />
</template>

<script>
import ProfileWrapper from '@/components/profile/ProfileWrapper'

export default {
  name: 'Profile',
  components: {
    ProfileWrapper
  }
}
</script>
