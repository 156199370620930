<template>
  <v-container
    fluid
    class="pt-0"
  >
    <app-api-call
      class="ma-0 pa-0 mb-3"
      :call-result="callResult"
    />

    <v-expansion-panels
      class="ma-0 pa-0"
    >
      <v-expansion-panel
        class="greyee"
      >
        <v-expansion-panel-header>
          {{ $i18n.t('$l.profile.activateSusrRkuv') }}
          <span
            v-if="profileDataLoaded"
            class="ml-3"
          >
            <span
              v-if="susrRkuvCredentials.enabled === true"
              class="success--text"
            >
              {{ $i18n.t('$l.profile.activated') }}
            </span>
            <span
              v-else
              class="error--text"
            >
              {{ $i18n.t('$l.profile.notActivated') }}
            </span>
          </span>
          <span
            v-else
            class="ml-3 info1--text"
          >
            {{ $i18n.t('$l.profile.activationStatusUnknown') }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SusrRkuvSettings
            v-if="profileDataLoaded"
            :susr-rkuv-credentials="susrRkuvCredentials"
            @update-susr-rkuv="updateSusrRkuvCredentials"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import SusrRkuvSettings from './SusrRkuvSettings'

export default {
  name: 'DatabaseSourceSettings',
  components: {
    SusrRkuvSettings
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      susrRkuvCredentials: {},
      profileDataLoaded: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/susrRkuvCredentials')
        .then(r => {
          this.susrRkuvCredentials = r.data
          this.profileDataLoaded = true
          if (r.data) {
            this.susrRkuvCredentials = r.data
          } else {
            this.susrRkuvCredentials.enabled = false
          }
        })
        .catch(e => {
          this.callResult.error = this.$i18n.t('$l.error.profileSettingsVerification')
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    updateSusrRkuvCredentials (susrRkuvCredentials) {
      this.susrRkuvCredentials = susrRkuvCredentials
    }
  }
}
</script>
