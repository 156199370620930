<template>
  <v-container
    fluid
  >
    <app-api-call
      class="ma-0 pa-0 mb-3"
      :call-result="callResult"
    />

    <ApiUsersManagement
      v-if="callResult.finished && !callResult.error"
    />
  </v-container>
</template>

<script>
import ApiUsersManagement from './ApiUsersManagement.vue'

export default {
  name: 'ApiUsersWrapper',
  components: {
    ApiUsersManagement
  },
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      clientInvestigationApiUsers: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.checkingRights')
      this.$xapi.post('endpointsUserProfile/hasRequiredRights', ['RIGHT_APP_ENABLE_PER_CLIENT_INVESTIGATION_API'])
        .then(r => {
          this.callResult.authorized = true
          this.clientInvestigationApiUsers.push(JSON.parse('{"user": "mla@ds.eu"}'))
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
          this.callResult.info = null
        })
    }
  }
}
</script>
