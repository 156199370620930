<template>
  <v-container
    fluid
  >
    <v-expansion-panels
      v-model="openPanels"
      class="ma-0 pa-0 mt-4"
      multiple
    >
      <v-expansion-panel
        v-for="(item, idx) in items"
        :key="idx"
      >
        <v-expansion-panel-header
          :class="item.color"
        >
          {{ item.label | translate }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="item.component"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import BasicUserData from './BasicUserData'
import LocaleSettings from './LocaleSettings'
import DatabaseSourceSettings from './DatabaseSourceSettings'
import ApiUsersWrapper from './ApiUsersWrapper'

export default {
  name: 'SettingsWrapper',
  components: {
    BasicUserData,
    LocaleSettings,
    DatabaseSourceSettings,
    ApiUsersWrapper
  },
  data () {
    return {
      openPanels: [-1],
      items: [
        { label: '$l.settings.basicUserData', component: 'BasicUserData', color: 'info1--text' },
        { label: '$l.profile.localeSettings', component: 'LocaleSettings' },
        { label: '$l.profile.databaseSourceSettings', component: 'DatabaseSourceSettings' },
        { label: '$l.profile.manageApiUsers', component: 'ApiUsersWrapper' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {.v-expansion-panel-content__wrap {
    padding: 0px !important;

  }}
</style>
