<template>
  <div>
    <app-api-call
      :call-result="callResult"
      class="mb-3"
    />

    <v-row
      dense
    >
      <v-col
        xl="9"
        lg="9"
        md="8"
        sm="12"
        xs="12"
        cols="12"
      >
        <basic-resp-row
          left="$l.api.apiKey"
          :right="apiKey"
          :call-result="callResult"
          width="100%"
          rowcss="body-2 ma-0 mt-0 mb-0 info1--text"
        />
      </v-col>
      <v-col
        xl="3"
        lg="3"
        md="4"
        sm="12"
        xs="12"
        cols="12"
        class="text-end"
      >
        <v-btn
          class="error"
          text
          small
          @click="resetApiKeyDialog = true"
        >
          {{ $i18n.t('$l.api.resetApiKey') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :mobile-breakpoint="0"
      :loading="!callResult.finished"
      class="ma-0 pa-0 okaml_cursor_pointer mt-3 greyee"
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="greyee ma-0 pa-0 mb-3 pr-0"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            :disabled="false"
            class="primary ma-0"
            text
            dark
            icon
            width="34"
            height="34"
            @click="addUserDialog = true"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        <span style="white-space: nowrap">{{ $utils.formatDate(item.created) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="error"
          text
          small
          @click="deleteApiUser(item)"
        >
          {{ $i18n.t('$l.app.delete') }}
        </v-btn>
      </template>
    </v-data-table>

    <basic-dialog
      v-model="addUserDialog"
      title="$l.profile.newApiUser"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="loadingSaveButton"
      @close-dialog="closeAddUserDialog()"
      @save-dialog="doAddApiUser()"
    >
      <v-container>
        <v-text-field
          v-model="newApiUserName"
          :label="'$l.profile.newApiUser' | translate"
          maxlength="128"
          counter
          :error-messages="($v.newApiUserName.$dirty && !$v.newApiUserName.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.newApiUserName.$touch()"
          @blur="$v.newApiUserName.$touch()"
        />
        <v-text-field
          v-model="newApiUserPassword"
          :label="'$l.api.apiUserPassword' | translate"
          maxlength="128"
          counter
          :error-messages="($v.newApiUserPassword.$dirty && !$v.newApiUserPassword.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.newApiUserPassword.$touch()"
          @blur="$v.newApiUserPassword.$touch()"
        />
        <v-text-field
          v-model="newApiUserAffiliate"
          :label="'$l.api.apiUserAffiliate' | translate"
          maxlength="128"
          counter
          :error-messages="($v.newApiUserAffiliate.$dirty && !$v.newApiUserAffiliate.required) ? $i18n.t('$l.app.required') : ''"
          @input="$v.newApiUserAffiliate.$touch()"
          @blur="$v.newApiUserAffiliate.$touch()"
          @keydown.enter="doAddApiUser()"
        />
        <v-alert
          v-if="addApiUserError"
          dense
          outlined
          class="mt-3 pa-1"
          color="error"
        >
          {{ addApiUserError }}
        </v-alert>
      </v-container>
    </basic-dialog>

    <basic-dialog
      v-if="apiUserToDelete !== null"
      v-model="apiUserDeleteDialog"
      title="$l.profile.confirmApiUserDeletion"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="loadingSaveButton"
      @close-dialog="apiUserDeleteDialog = false"
      @save-dialog="doDeleteApiUser()"
    >
      <v-container>
        {{ apiUserToDelete.apiUser }}
      </v-container>
    </basic-dialog>

    <basic-dialog
      v-model="resetApiKeyDialog"
      title="$l.api.confirmResetApiKey"
      save-button
      save-button-label="$l.app.confirm"
      :loading-save-button="loadingSaveButton"
      @close-dialog="resetApiKeyDialog = false"
      @save-dialog="doResetApiKey()"
    >
      <v-alert
        dense
        outlined
        class="ma-3 pa-1"
        color="error"
      >
        {{ $i18n.t('$l.api.warningResetApiKey') }}
      </v-alert>
    </basic-dialog>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ApiUsersManagement',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      items: [],
      search: '',
      addUserDialog: false,
      apiUserDeleteDialog: false,
      apiUserToDelete: null,
      loadingSaveButton: false,
      addApiUserError: null,
      newApiUserName: null,
      newApiUserPassword: null,
      newApiUserAffiliate: null,
      apiKey: null,
      resetApiKeyDialog: false
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('$l.api.apiUserName'), value: 'apiUser' },
        { text: this.$t('$l.api.apiUserAffiliate'), value: 'apiAffiliate' },
        { text: this.$t('$l.case.created'), value: 'created', align: 'right', sortable: true },
        { text: this.$t('$l.app.actions'), value: 'actions', align: 'end' }
      ]
    }
  },
  validations: {
    newApiUserName: { required },
    newApiUserPassword: { required },
    newApiUserAffiliate: { required }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.callResult.finished = false
      this.callResult.error = null
      this.callResult.info = this.$i18n.t('$l.info.loadingData')
      this.$xapi.get('settings/apiuser')
        .then(r => {
          this.items = r.data
          this.customFilter()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.$xapi.get('settings/apiparentuserhash/get')
            .then(r => {
              this.apiKey = r.data
            })
            .catch(e => {
              this.callResult.error = e.response.localizedMessage
            })
            .finally(() => {
              this.callResult.finished = true
              this.callResult.info = null
            })
        })
    },
    customFilter () {
    },
    deleteApiUser (item) {
      this.apiUserToDelete = item
      this.apiUserDeleteDialog = true
    },
    doDeleteApiUser () {
      this.callResult.error = null
      if (this.apiUserToDelete === null) return
      this.loadingSaveButton = true
      this.$xapi.delete(`settings/apiuser/delete/${this.apiUserToDelete.apiUser}`)
        .then(r => {
          this.init()
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.apiUserDeleteDialog = false
          this.loadingSaveButton = false
        })
    },
    doAddApiUser () {
      this.addApiUserError = null
      this.loadingSaveButton = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.loadingSaveButton = false
        return
      }
      this.$xapi.put('settings/apiuser', { apiUser: this.newApiUserName, apiUserPassword: this.newApiUserPassword, apiAffiliate: this.newApiUserAffiliate })
        .then(r => {
          this.newApiUserName = null
          this.newApiUserPassword = null
          this.newApiUserAffiliate = null
          this.addUserDialog = false
          this.$v.$reset()
          this.init()
        })
        .catch(e => {
          this.addApiUserError = e.response.localizedMessage
        })
        .finally(() => {
          this.loadingSaveButton = false
        })
    },
    closeAddUserDialog () {
      this.addApiUserError = null
      this.newApiUserName = null
      this.newApiUserPassword = null
      this.newApiUserAffiliate = null
      this.addUserDialog = false
      this.$v.$reset()
    },
    doResetApiKey () {
      this.callResult.error = null
      this.loadingSaveButton = true
      this.$xapi.get('settings/apiparentuserhash/reset')
        .then(r => {
          this.apiKey = r.data
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loadingSaveButton = false
          this.resetApiKeyDialog = false
        })
    }
  }
}
</script>
