<template>
  <div class="ma-3">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          width="50"
          height="50"
          class="white ma-0 pa-0"
          :class="{'faded': $store.getters.getLocale === 'sk'}"
          @click="$store.commit('SET_LOCALE', { locale: 'sk', ri18n: $root.$i18n })"
          v-on="on"
        >
          <v-avatar
            size="50"
            class="white ma-0 pa-0"
          >
            <img :src="require(`@/assets/slovakia.svg`)">
          </v-avatar>
        </v-btn>
      </template>
      <span>Slovensky</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn
          icon
          width="50"
          height="50"
          class="white ma-0 pa-0 ml-3"
          :class="{'faded': $store.getters.getLocale === 'en'}"
          @click="$store.commit('SET_LOCALE', { locale: 'en', ri18n: $root.$i18n })"
          v-on="on"
        >
          <v-avatar
            size="50"
            class="white ma-0 pa-0"
          >
            <img :src="require(`@/assets/united-states.svg`)">
          </v-avatar>
        </v-btn>
      </template>
      <span>English</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'LocaleSettings'
}
</script>

<style lang="scss" scoped>
  .faded {
    opacity: 0.3;
    background-color: #ddd;
  }
</style>
