<template>
  <div class="pa-3 pt-0">
    <a
      href="https://slovak.statistics.sk/wps/portal/ext/services/user"
      target="_blank"
      class="info1--text text-caption"
      style="text-decoration: none; word-wrap: break-word;"
    >
      <span
        class="secondary--text"
      >
        {{ $i18n.t('$l.profile.dbSourceWeb') }}
      </span>
      https://slovak.statistics.sk/wps/portal/ext/services/user
    </a>

    <app-api-call
      class="ma-0 pa-0 my-3"
      :call-result="callResult"
    />

    <v-progress-circular
      v-if="activationInProgress"
      class="float-right d-flex"
      color="primary"
      indeterminate
      size="24"
      width="3"
    />

    <v-switch
      v-model="susrRkuvCredentialsEnabled"
      class="ma-0 pa-0 mt-3 mb-5"
      :label="$i18n.t('$l.app.activate')"
      :error-messages="switchError"
      @change="swichRkuvActivation"
    />

    <v-container
      class="ma-0 pa-0"
    >
      <v-row
        dense
      >
        <v-col
          cols="12"
          xl="2"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-text-field
            v-model="susrRkuvCredentials.username"
            autocomplete="new-password"
            :label="$i18n.t('$l.profile.username')"
            :error-messages="($v.susrRkuvCredentials.username.$dirty && !$v.susrRkuvCredentials.username.required) ? $i18n.t('$l.app.required') : ''"
            dense
            outlined
            maxlength="64"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-text-field
            v-model="susrRkuvCredentials.password"
            autocomplete="new-password"
            :type="hidenPassword ? 'password' : 'text'"
            :append-icon="hidenPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            :label="$i18n.t('$l.profile.password')"
            :error-messages="($v.susrRkuvCredentials.password.$dirty && !$v.susrRkuvCredentials.password.required) ? $i18n.t('$l.app.required') : ''"
            dense
            outlined
            maxlength="32"
            counter
            @click:append="() => (hidenPassword = !hidenPassword)"
          />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-text-field
            v-model="susrRkuvCredentials.companyName"
            autocomplete="new-password"
            :label="$i18n.t('$l.profile.companyName')"
            :error-messages="($v.susrRkuvCredentials.companyName.$dirty && !$v.susrRkuvCredentials.companyName.required) ? $i18n.t('$l.app.required') : ''"
            dense
            outlined
            maxlength="64"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-text-field
            v-model="susrRkuvCredentials.ico"
            autocomplete="new-password"
            :label="$i18n.t('$l.profile.ico')"
            :error-messages="($v.susrRkuvCredentials.ico.$dirty && !$v.susrRkuvCredentials.ico.required) ? $i18n.t('$l.app.required') : ''"
            dense
            outlined
            maxlength="32"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-text-field
            v-model="susrRkuvCredentials.name"
            autocomplete="new-password"
            :label="$i18n.t('$l.profile.name')"
            :error-messages="($v.susrRkuvCredentials.name.$dirty && !$v.susrRkuvCredentials.name.required) ? $i18n.t('$l.app.required') : ''"
            dense
            outlined
            maxlength="64"
            counter
          />
        </v-col>
        <v-col
          cols="12"
          xl="2"
          lg="4"
          md="4"
          sm="6"
          xs="12"
        >
          <v-text-field
            v-model="susrRkuvCredentials.surname"
            autocomplete="new-password"
            :label="$i18n.t('$l.profile.surname')"
            :error-messages="($v.susrRkuvCredentials.surname.$dirty && !$v.susrRkuvCredentials.surname.required) ? $i18n.t('$l.app.required') : ''"
            dense
            outlined
            maxlength="64"
            counter
          />
        </v-col>
      </v-row>

      <v-row
        dense
        class="text-end"
      >
        <v-col>
          <v-btn
            class="primary"
            :loading="testInProgress"
            text
            @click="testService"
          >
            {{ "$l.profile.testService" | translate }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div
      v-if="testResponse != null"
      class="mt-3"
    >
      <span
        v-if="testResponse.resultStatusCode"
      >
        <span
          v-if="testResponse.resultStatusCode === '0' || testResponse.resultStatusCode === '1'"
          class="info1--text"
        >
          {{ formattedResponse }}
        </span>
        <span
          v-else
          class="info1--text"
        >
          <span
            v-if="testResponse.resultStatusCode === '2' || testResponse.resultStatusCode === '3'"
          >
            {{ $i18n.t('$l.profile.rkuvAvailableButNoResults').replace('#1', susrRkuvCredentials.ico ) }}
          </span>
          <span
            v-else
            class="error--text"
          >
            {{ $i18n.t('$l.profile.rkuvAvailableButResponseError').replace('#1', testResponse.resultStatusCode ) }}
          </span>
        </span>
      </span>
      <span
        v-else
        class="error--text"
      >
        {{ testResponse.error }}
      </span>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'SusrRkuvSettings',
  props: {
    susrRkuvCredentials: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      callResult: { finished: true, authorized: false, error: null, info: null },
      susrRkuvCredentialsEnabled: false,
      testInProgress: false,
      testResponse: null,
      formattedResponse: '',
      switchError: '',
      activationInProgress: false,
      hidenPassword: true
    }
  },
  validations: {
    susrRkuvCredentials: {
      username: { required },
      password: { required },
      ico: { required },
      companyName: { required },
      name: { required },
      surname: { required }
    }
  },
  created () {
    this.susrRkuvCredentialsEnabled = this.susrRkuvCredentials.enabled
  },
  methods: {
    swichRkuvActivation (val) {
      if (val) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.susrRkuvCredentialsEnabled = false
            }, 400)
          })
        } else {
          if (this.testResponse && this.testResponse.success) {
            this.enableService()
          } else {
            this.switchError = this.$i18n.t('$l.profile.runSuccessfulTestFirst')
            this.$nextTick(() => {
              setTimeout(() => {
                this.susrRkuvCredentialsEnabled = false
              }, 400)
            })
          }
        }
      } else {
        this.disableService()
      }
    },
    enableService () {
      this.callResult.error = null
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.activationInProgress = true
      this.$xapi.put('settings/rkuv/enable', this.susrRkuvCredentials)
        .then(r => {
          this.$emit('update-susr-rkuv', r.data)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.activationInProgress = false
          this.susrRkuvCredentialsEnabled = this.susrRkuvCredentials.enabled
        })
    },
    disableService () {
      this.callResult.error = null
      this.activationInProgress = true
      this.$xapi.put('settings/rkuv/disable', this.susrRkuvCredentials)
        .then(r => {
          this.$emit('update-susr-rkuv', r.data)
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.activationInProgress = false
          this.susrRkuvCredentialsEnabled = this.susrRkuvCredentials.enabled
        })
      this.callResult.error = null
      this.testResponse = {}
    },
    testService () {
      this.switchError = ''
      this.testResponse = null
      this.formattedResponse = ''
      this.callResult.error = null
      this.testInProgress = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.testInProgress = false
        return
      }
      this.$xapi.post('settings/rkuv/test', this.susrRkuvCredentials)
        .then(r => {
          this.testResponse = r.data
          let kuvCount = this.testResponse.normalizedPersonList.filter(p => p.type === 'kuv').length
          this.formattedResponse = this.$i18n.t('$l.profile.rkuvAvailableWitResults').replace('#1', this.susrRkuvCredentials.ico).replace('#2', kuvCount)
          this.testResponse.success = true
        })
        .catch(e => {
          this.testResponse = {}
          if (e.response.status === 400) {
            this.testResponse.error = this.$i18n.t('$l.profile.rkuvTestCredentialsInserted')
          } else if (e.response.status === 403) {
            this.testResponse.error = this.$i18n.t('$l.profile.rkuvNotAuthorizedAccess')
          } else {
            this.testResponse.error = this.$i18n.t('$l.profile.rkuvNotAvailable')
          }
        })
        .finally(() => {
          this.testInProgress = false
        })
    }
  }
}
</script>
